// @flow

import type { Node } from 'react'
import React from 'react'
import { Heading1, Layout, PillButtonLink } from '../components'
import ContactForm from '../scenes/contact'
import LocationMap from '../scenes/location-map'

function ContactUsPage(): Node {
  return (
    <Layout>
      <div className="bg-purple text-white">
        <LocationMap isMarkerShown />
        <div className="pt-8 pb-2 md:pt-12 px-8">
          <div className="w-full max-w-xl mx-auto">
            <div className="max-w-sm">
              <Heading1 className="mb-2" isTitle>
                Not Accepting Reservations
              </Heading1>
              <p className="text-purple-lightest leading-normal">
                <span>
                  Cottages of Paradise is no more. You are no longer able to
                  make reservations due to{' '}
                </span>
                <a
                  href="https://www.washingtonpost.com/nation/2022/10/14/lee-county-hurricane-ian-evacuation-timeline/"
                  className="text-purple-lightest"
                >
                  Hurricane Ian
                </a>
                <span>.</span>
              </p>
              <div className="mt-4 inline-flex">
                <PillButtonLink to="/blog/cottages-of-paradise-point-is-no-more">
                  Read Update
                </PillButtonLink>
              </div>
            </div>
            <section id="contact-form" className="max-w-md mb-16 mt-12">
              <ContactForm formName="contact" />
            </section>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactUsPage
